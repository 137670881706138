import React from "react";

const Container = ({ children, className }) => {
    return (
      <div
        className={[
          'container max-w-screen-lg px-8 mx-auto lgxl:mr-0 xl:mr-auto',
          className,
        ].join(' ')}
      >
        {children}
      </div>
    );
  };
  
  export default Container;
  