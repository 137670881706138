import React from "react";

const H3 = ({ heading, className }) => {
  return (
    <h3
      className={[
        "mb-7 text-2xl md:text-3xl font-medium text-primary-text",
        className,
      ].join(" ")}
    >
      {heading}
    </h3>
  );
};

export default H3;
